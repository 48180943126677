import React from 'react';
import Slider from 'react-slick';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Slideshow = ({ data }) => {
  const settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 6000,
    dots: false,
    draggable: false,
    fade: true,
    infinite: true,
    lazyLoad: true,
    pauseOnHover: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
  };

  return (
    <Slider {...settings} className="w-full">
      {data.prismicHome.data.hero_images.map(({ image }, id) => (
        <Img
          fluid={image.localFile.childImageSharp.fluid}
          key={id}
          className="flex py-32 md:py-0"
          alt="structrure-plus"
        />
      ))}
    </Slider>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query slides {
        prismicHome {
          id
          data {
            hero_images {
              image {
                url
                localFile {
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 1500, maxHeight: 800) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <Slideshow data={data} {...props} />}
  />
);
