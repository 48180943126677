import React from 'react';
import { Link as GatsbyLink, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { MdChevronRight } from 'react-icons/md';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import CallToACtion from '../components/CallToAction';
import Mark from '../components/Mark';

import Slideshow from '../components/Slideshow';

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      meta_title={data.prismicHome.data.meta_title}
      meta_description={data.prismicHome.data.meta_description}
      hero_image={data.prismicHome.data.hero_image.url}
    />

    <div className="home-slideshow block relative bg-black">
      <Slideshow />

      <div className="absolute pin-b z-50 block flex w-full">
        <div className="container flex self-end pb-8">
          <div
            dangerouslySetInnerHTML={{
              __html: data.prismicHome.data.hero_text.html,
            }}
            className="z-10 relative text-white w-full md:w-2/3 lg:w-1/2"
          />
        </div>
      </div>

      <div className="absolute pin z-10 bg-black opacity-50" />
    </div>

    <section className="p-0 h-full text-white flex flex-wrap relative z-10">
      <div className="card w-full md:w-1/2 bg-red-dark -mt-6 md:mt-0 -mb-6 md:mb-0">
        <div className="card-image flex flex-wrap bg-red-darker relative">
          <div className="absolute pin z-0">
            <Img fluid={data.mitigationImage.childImageSharp.fluid} style={{ position: 'initial' }} />
          </div>
          <h2 className="title self-end container md:px-12 max-w-md z-10">{data.mitigationTag.data.tag.text}</h2>
        </div>
        <div className="card-content pb-12 pt-4 container md:p-12 max-w-md">
          <div
            dangerouslySetInnerHTML={{
              __html: data.mitigationTag.data.tag_description.html,
            }}
          />
          <ul className="list-reset m-0 p-0 flex flex-wrap md:-mx-6">
            {data.mitigation.edges.map(({ node }) => (
              <li key={node.id} className="w-full md:w-1/2 md:px-6">
                <GatsbyLink
                  to={`services/${node.uid}`}
                  className="no-underline border-b border-white py-2 text-white flex justify-between hover:text-red-darkest"
                >
                  {node.data.title.text}
                  <span className="flex self-center text-xl">
                    <MdChevronRight />
                  </span>
                </GatsbyLink>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="w-full text-center relative md:absolute z-50 self-center md:-mt-6">
        <span className="bg-white rounded-full w-12 h-12 block m-auto p-2 shadow-md">
          <Mark />
        </span>
      </div>

      <div className="card w-full md:w-1/2 bg-blue -mt-6 md:mt-0">
        <div className="card-image flex flex-wrap bg-blue-darker relative">
          <div className="absolute pin z-0">
            <Img fluid={data.remodelingImage.childImageSharp.fluid} style={{ position: 'initial' }} />
          </div>
          <h2 className="title self-end container md:px-12 max-w-md z-10">{data.remodelingTag.data.tag.text}</h2>
        </div>
        <div className="card-content pb-12 pt-4 container md:p-12 max-w-md">
          <div
            dangerouslySetInnerHTML={{
              __html: data.remodelingTag.data.tag_description.html,
            }}
          />
          <ul className="list-reset m-0 p-0 flex flex-wrap">
            {data.remodeling.edges.map(({ node }) => (
              <li key={node.id} className="w-full">
                <GatsbyLink
                  to={`services/${node.uid}`}
                  className="no-underline border-b border-white py-2 text-white flex justify-between hover:text-blue-darkest"
                >
                  {node.data.title.text}
                  <span className="flex self-center text-xl">
                    <MdChevronRight />
                  </span>
                </GatsbyLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>

    <CallToACtion />
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query homePage {
    prismicHome {
      uid
      id
      data {
        meta_title
        meta_description
        title {
          text
        }
        content {
          html
          text
        }
        hero_text {
          html
        }
        hero_image {
          url
        }
      }
    }
    prismicServices {
      uid
      id
      data {
        title {
          html
          text
        }
        content {
          html
          text
        }
      }
    }
    remodeling: allPrismicService(
      filter: { data: { service_type: { eq: "Remodeling and Construction" } } }
      sort: { fields: data___title___text, order: ASC }
    ) {
      edges {
        node {
          ...homeServiceFragment
        }
      }
    }
    mitigation: allPrismicService(
      filter: { data: { service_type: { eq: "Mitigation and Emergency Services" } } }
      sort: { fields: data___title___text, order: ASC }
    ) {
      edges {
        node {
          ...homeServiceFragment
        }
      }
    }
    mitigationImage: file(relativePath: { eq: "structure-plus_10810626_xl-2015-red.jpg" }) {
      childImageSharp {
        fluid(quality: 80, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    remodelingImage: file(relativePath: { eq: "structure-plus_8656403_xl-2015-blue.jpg" }) {
      childImageSharp {
        fluid(quality: 80, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mitigationTag: prismicTags(data: { tag: { text: { eq: "Mitigation and Emergency Services" } } }) {
      id
      data {
        tag {
          text
        }
        tag_description {
          html
        }
      }
    }
    remodelingTag: prismicTags(data: { tag: { text: { eq: "Remodeling and Construction Services" } } }) {
      id
      data {
        tag {
          text
        }
        tag_description {
          html
        }
      }
    }
  }

  fragment homeServiceFragment on PrismicService {
    uid
    id
    data {
      title {
        html
        text
      }
      content {
        html
        text
      }
      service_type
    }
  }
`;
