import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

const CallToAction = ({ data }) => (
  <BackgroundImage Tag="div" className="py-20 relative" fluid={data.ctaImage.childImageSharp.fluid}>
    <div className="w-full text-center text-white z-10 relative">
      <h2>
        Let's Get Started <br />
        Call Us Today {data.prismicGlobal.data.phone_number.text}
      </h2>
    </div>
  </BackgroundImage>
);

export default props => (
  <StaticQuery
    query={graphql`
      query cta {
        ctaImage: file(relativePath: { eq: "structure-plus_52677099_xl-2015.jpg" }) {
          childImageSharp {
            fluid(quality: 80, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        prismicGlobal {
          id
          data {
            address {
              html
            }
            phone_number {
              text
            }
          }
        }
      }
    `}
    render={data => <CallToAction data={data} {...props} />}
  />
);
